import React, { useState, useRef, useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container, useMediaQuery } from '@material-ui/core';

import { Hero } from '../components/General/Hero';
import { FAQ } from '../components/General/FAQ';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
// import { Form } from '../components/ContactForm/Form';
import FormModalContext from '../context/FormModalContext';

const Form = loadable(() => import('../components/ContactForm/Form'));

const TermsAndConditionsPage = ({ data, location }) => {
	const terms = data.terms.edges[0].node;
	const [expanded, setExpanded] = useState(null);
	const faqRef = useRef(null);

	const { metaDescription, metaTitle, hero, pageContent } = terms;

	const { formModalOpen } = useContext(FormModalContext);

	//workwave.com/general-terms-and-conditions?linkedindex=2
	const params = new URLSearchParams(location.search);
	const linkedIndex = params.get('linkedindex');
	const lg = useMediaQuery('(max-width: 1280px)');

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} />
			<Container style={{ marginBottom: lg ? '0rem' : '16rem' }}>
				<div ref={faqRef} />
				{pageContent.map((faq, index) => (
					<FAQ
						key={index}
						faq={faq}
						index={index}
						expanded={expanded}
						setExpanded={setExpanded}
						faqRef={faqRef}
						linkedIndex={linkedIndex}
					/>
				))}
				{formModalOpen ? (
					<Form
						privacy
						noForm
						location={location}
						pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
					/>
				) : null}
			</Container>
			<div style={{ marginTop: '12rem' }}>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query TermsAndConditionsPageQuery {
		terms: allSanityTermsAndConditions {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					pageContent {
						question
						_rawAnswer
					}
				}
			}
		}
	}
`;

export default TermsAndConditionsPage;
